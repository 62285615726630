import {
  BskyAgent,
  ComAtprotoServerTelegramChannels,
  ComAtprotoServerTelegramParticipants,
} from '@atproto/api'
import {Api, TelegramClient} from '@sipz/telegram'
import type {Dialog} from 'telegram/tl/custom/dialog'

import {uploadBlobImage} from '#/lib/api'
import {emitter} from '#/state/events'
import DialogQueue from './DialogQueue'
import ParticipantsQueue from './ParticipantsQueue'
import {ICurrentCircleState} from './types'
export const preMap = new Map<number, Api.TypeUser[]>()

export async function getContacts(
  client: TelegramClient,
): Promise<Api.contacts.Contacts> {
  await client.connect()
  const res = await client.invoke(
    new Api.contacts.GetContacts({
      hash: BigInt('-4156887774564') as any,
    }),
  )
  console.log('getContacts', res)
  return res as Api.contacts.Contacts
}

const requestFullChannel = (id: bigInt.BigInteger) => {
  return new Api.channels.GetFullChannel({
    channel: id,
  })
}

const requestFullChat = (id: bigInt.BigInteger) => {
  return new Api.messages.GetFullChat({
    chatId: id,
  })
}

export const getChatUsers = async (
  client: TelegramClient,
  id: bigInt.BigInteger,
) => {
  const fullChat = await client.invoke(requestFullChat(id))
  return fullChat
}

const requestParticipants = (id: bigInt.BigInteger) => {
  return new Api.channels.GetParticipants({
    channel: id,
    filter: new Api.ChannelParticipantsRecent(),
    offset: 0,
    limit: 200,
    hash: BigInt('-4156887774564'),
  })
}

async function saveParticipants(
  agent: BskyAgent,
  fullChat: Api.messages.ChatFull | Api.channels.ChannelParticipants,
  chat?: ComAtprotoServerTelegramParticipants.Chat,
) {
  try {
    await agent.com.atproto.server.telegramParticipants(
      getChatFullData(fullChat, chat),
    )
  } catch (err) {
    console.log('telegramParticipants-error:', err)
    console.log('telegramParticipants-error-fullChat:', fullChat)
    console.log('telegramParticipants-error-chat:', chat)
  }
}

export async function getTelegramDialogs(
  client: TelegramClient,
  agent: BskyAgent,
) {
  await client.connect()
  const allDialogs = await client.getDialogs()

  const dialogs = allDialogs
    .filter(d => d.isChannel || d.isGroup)
    .filter(d => ((d.message?._chat as any)?.participantsCount || 0) <= 200)

  const task = async (dialog: Dialog) => {
    if (dialog.isChannel) {
      const fullChannel: Api.messages.ChatFull = await client.invoke(
        requestFullChannel(
          (dialog.inputEntity as Api.InputPeerChannel).channelId,
        ),
      )
      const channeFull = await client.invoke(
        requestParticipants(fullChannel.fullChat.id),
      )
      console.log('Participants:', channeFull)
      if (channeFull.count > 0) {
        await saveParticipants(agent, channeFull, {
          id: +(dialog.id || 0).toString(),
          title: dialog.title || dialog.name,
          participants_count: channeFull.participants?.length || 0,
        })
      }
      console.log('fullChannel:', fullChannel)
      const subChats = await Promise.allSettled(
        fullChannel.chats.map(chat =>
          client.invoke(requestParticipants(chat.id)),
        ),
      )

      console.log('Participants-sub-channel:', subChats)

      try {
        const fulfilledChats = subChats
          .filter(item => item.status === 'fulfilled')
          .map(item => item.value)
        await Promise.all(
          fulfilledChats.map((chat, index) =>
            saveParticipants(agent, chat, {
              id: +fullChannel.chats[index].id.toString(),
              title: (fullChannel.chats[index] as Api.Chat).title,
              participants_count: chat.participants?.length || 0,
            }),
          ),
        ).catch(err => console.log(err))

        // await saveChannels(
        //   agent,
        //   fulfilledChats.map((chat, index) => ({
        //     id: +fullChannel.chats[index].id.toString(),
        //     title: (fullChannel.chats[index] as Api.Chat).title,
        //     participants_count: chat.participants?.length || 0,
        //     /** is this a channel */
        //     broadcast: (fullChannel.chats[index] as Api.Channel).broadcast,
        //     /** is this a super group */
        //     megagroup: (fullChannel.chats[index] as Api.Channel).megagroup,
        //   })),
        // )
      } catch (err) {
        console.log('sub channel chat Participant save error:', err)
      }
      return true
    } else if (dialog.isGroup) {
      const fullChat = await client.invoke(
        requestFullChat((dialog.inputEntity as Api.InputPeerChat).chatId),
      )
      // console.log('Participant-group:', fullChat)
      if (fullChat.users.length > 0) {
        await saveParticipants(agent, fullChat)
        // await saveChannels(
        //   agent,
        //   fulfilledChats.map((chat, index) => ({
        //     id: +fullChannel.chats[index].id.toString(),
        //     title: (fullChannel.chats[index] as Api.Chat).title,
        //     participants_count: chat.participants?.length || 0,
        //     /** is this a channel */
        //     broadcast: (fullChannel.chats[index] as Api.Channel).broadcast,
        //     /** is this a super group */
        //     megagroup: (fullChannel.chats[index] as Api.Channel).megagroup,
        //   })),
        // )
      }
      return true
    }
    console.log('lose:', dialog)
    return true
  }
  const queue = new DialogQueue({
    queue: dialogs,
    task,
  })

  queue.run()
}

export function getChatFullData(
  chat: Api.messages.ChatFull | Api.channels.ChannelParticipants,
  chatData?: ComAtprotoServerTelegramParticipants.Chat,
) {
  let participants
  if (chat.className === 'messages.ChatFull') {
    participants = (
      (chat.fullChat as Api.ChatFull).participants as Api.ChatParticipants
    ).participants
  } else {
    participants = (chat as Api.channels.ChannelParticipants).participants
  }
  const chats = (chat.chats as Api.Chat[]).map(item => ({
    id: +item.id.toString(),
    title: item.title,
    participants_count: item.participantsCount,
  }))
  return {
    count: participants.length,
    participants: participants.map(p => ({
      user_id: +(p as any).userId.toString(),
    })),
    chats: chats.length > 0 ? chats : chatData ? [chatData] : [],
    users: (chat.users as Api.User[]).map(u => ({
      id: +u.id.toString(),
    })),
    // users: (chat.users as Api.User[]).map(u => ({
    //   id: +u.id.toString(),
    //   username: u.username || '',
    //   firstName: u.firstName || '',
    //   lastName: u.lastName || '',
    //   /** The telegram user number. */
    //   phone_number: u.phone || '',
    // })),
  }
}

export function getChannelFullData(
  agent: BskyAgent,
  client: TelegramClient,
  channelFull: Api.messages.ChatFull[],
) {
  const task = async (
    id: bigInt.BigInteger,
  ): Promise<Api.channels.ChannelParticipants | null> => {
    try {
      return client.invoke(
        new telegram.tl.Api.channels.GetParticipants({
          channel: id,
          filter: new telegram.tl.Api.ChannelParticipantsRecent({}),
          offset: 0,
          limit: 200,
          hash: BigInt('-4156887774564'),
        }),
      )
    } catch (err) {
      return null
    }
  }

  const queue = new ParticipantsQueue({
    queue: channelFull,
    task,
    resultFormat: (
      chat: Api.messages.ChatFull,
      participants: Api.channels.ChannelParticipants,
    ) => {
      const _participants = participants.participants.map(p => ({
        user_id: (p as Api.ChannelParticipant).userId,
      }))

      agent.com.atproto.server.telegramParticipants({
        count: (chat.fullChat as Api.ChannelFull).participantsCount || 0,
        participants: _participants.map(item => ({
          user_id: +item.user_id.toString(),
        })),
        chats: (chat.chats as Api.Channel[]).map(item => ({
          id: +item.id.toString(),
          title: item.title,
          participants_count: participants.participants.length || 0,
        })),
      })
    },
  })
  queue.run()
}

async function saveChannels(
  agent: BskyAgent,
  chats: ComAtprotoServerTelegramChannels.Chat[],
) {
  try {
    await agent.com.atproto.server.telegramChannels({
      channels: {
        chats: chats,
      },
    })
  } catch (err) {
    console.log('telegramChannels-error:', err)
  }
}

export async function getChannelChats(
  client: TelegramClient,
  agent: BskyAgent,
) {
  await client.connect()
  const allDialogs = await client.getDialogs()

  const dialogs = allDialogs
    .filter(d => d.isChannel || d.isGroup)
    .filter(d => ((d.message?._chat as any)?.participantsCount || 0) <= 200)

  console.log(dialogs)

  const task = async (dialog: Dialog) => {
    if (dialog.isChannel) {
      const fullChannel: Api.messages.ChatFull = await client.invoke(
        requestFullChannel(
          (dialog.inputEntity as Api.InputPeerChannel).channelId,
        ),
      )

      await saveChannels(
        agent,
        (fullChannel.chats as Api.Channel[]).map(chat => ({
          id: parseInt(chat.id.toString(), 10),
          title: chat?.title,
          participants_count: 1,
          /** is this a channel */
          broadcast: chat.broadcast,
          /** is this a super group */
          megagroup: chat.megagroup,
        })),
      )
    } else if (dialog.isGroup) {
      try {
        const fullChat = await client.invoke(
          requestFullChat((dialog.inputEntity as Api.InputPeerChat).chatId),
        )
        console.log('save-group:', fullChat)
        await saveChannels(agent, [
          {
            id: parseInt(fullChat.chats[0].id.toString(), 10),
            title: fullChat.chats[0].title,
            participants_count: fullChat.fullChat.participants.length,
          },
        ])
      } catch (err) {
        console.log('getChannelChats', err)
      }
      return true
    } else {
    }
    return true
  }
  const queue = new DialogQueue({
    queue: dialogs,
    task,
  })

  queue.run()
}

export async function getCurrentCircleState(
  agent: BskyAgent,
  chats: any[],
): Promise<ICurrentCircleState[]> {
  const res = await agent.com.atproto.server.getTelegramGroupCircles({
    group_ids: chats.map(item => item.id),
  })
  const idMapTitle = new Map(chats.map(item => [item.id, item.title]))
  return new Promise<ICurrentCircleState[]>(async (resolve, reject) => {
    if (res.data.results) {
      const alreadyExistMap = new Map()
      res.data.results.forEach(circle => {
        if (circle?.circles && circle.circles.length) {
          alreadyExistMap.set(circle.group_id, circle.circles[0])
        }
      })
      const allChatsState = chats.map(chat => {
        if (alreadyExistMap.has(chat.id)) {
          return {
            id: chat.id,
            title: alreadyExistMap.get(chat.id)?.circle_name,
            photo: alreadyExistMap.get(chat.id)?.photo,
            circleId: alreadyExistMap.get(chat.id)?.circle_id,
            members: alreadyExistMap.get(chat.id)?.member_count,
            exited: true,
          }
        } else {
          return {
            id: chat.id,
            title: idMapTitle.get(chat.id),
            photo: '',
            exited: false,
          }
        }
      })

      resolve(allChatsState)
    } else {
      reject(
        chats.map(chat => {
          return {
            id: chat.id,
            title: chat.title,
            photo: '',
            exited: false,
          }
        }),
      )
    }
  })
}

interface IChatInfo {
  id: number
  title: string
}

export function getAllChats(client: TelegramClient) {
  return new Promise<any[]>(async (resolveGetAllChats, rejectGetAllChats) => {
    try {
      await client.connect()
      const allDialogs = await client.getDialogs()

      const dialogs = allDialogs
        .filter(d => d.isChannel || d.isGroup)
        .filter(d => ((d.message?._chat as any)?.participantsCount || 0) <= 200)

      const taskArray = dialogs.map(dialog => {
        return new Promise<IChatInfo | IChatInfo[] | undefined>(
          async resolve => {
            try {
              if (dialog.isChannel) {
                const anyResult: any[] = []
                const fullChannel: Api.messages.ChatFull = await client.invoke(
                  requestFullChannel(
                    (dialog.inputEntity as Api.InputPeerChannel).channelId,
                  ),
                )
                const channeFull = await client.invoke(
                  requestParticipants(fullChannel.fullChat.id),
                )
                if (channeFull.count > 0) {
                  // anyResult.push({
                  //   id: +(dialog.id || 0),
                  //   title: dialog.title || dialog.name,
                  // })
                  // preMap.set(+(dialog.id || 0), channeFull?.users || [])
                }
                const subChats = await Promise.allSettled(
                  fullChannel.chats.map(chat =>
                    client.invoke(requestParticipants(chat.id)),
                  ),
                )

                const fulfilledChats = subChats
                  .filter(item => item.status === 'fulfilled')
                  .map(item => item.value)
                fulfilledChats.map((chat, index) => {
                  anyResult.push({
                    id: +fullChannel.chats[index].id,
                    title: (fullChannel.chats[index] as Api.Chat).title,
                  })
                  preMap.set(+fullChannel.chats[index].id, chat?.users || [])
                })
                resolve(anyResult)
              } else if (dialog.isGroup) {
                const fullChat = await client.invoke(
                  requestFullChat(
                    (dialog.inputEntity as Api.InputPeerChat).chatId,
                  ),
                )
                resolve({
                  id: parseInt(fullChat.chats[0].id.toString(), 10),
                  title: fullChat.chats[0].title,
                })
              }
            } catch (error) {
              resolve(undefined)
            }
          },
        )
      })

      const taskResult = await Promise.all(taskArray)

      const seen = new Set()
      const uniqueArray: IChatInfo[] = []

      taskResult
        .flat()
        .filter(Boolean)
        .forEach(item => {
          if (item?.id && !seen.has(item.id)) {
            uniqueArray.push(item)
            seen.add(item.id)
          }
        })

      resolveGetAllChats(uniqueArray)
    } catch (error) {
      rejectGetAllChats(error)
    }
  })
}

export function normalizePhone(phone: string) {
  return phone?.replace(/\s+/g, '')
}

export function userAuthParamCallback<T>(param: T): () => Promise<T> {
  return async function () {
    return await new Promise<T>(resolve => {
      resolve(param)
    })
  }
}

export function concurPromise<T>(
  promises: Promise<T | Error>[],
  maxNum: number,
): Promise<(T | Error)[]> {
  return new Promise(resolve => {
    if (promises.length === 0) {
      return resolve([])
    }

    const results: (T | Error)[] = new Array(promises.length)
    let index = 0
    let activeCount = 0

    const runPromise = async (i: number) => {
      activeCount++
      try {
        const res = await promises[i]
        results[i] = res
      } catch (error) {
        results[i] = error instanceof Error ? error : new Error(String(error))
      } finally {
        activeCount--
        if (activeCount === 0 && index >= promises.length) {
          resolve(results)
        } else {
          dequeue()
        }
      }
    }

    const dequeue = () => {
      while (activeCount < maxNum && index < promises.length) {
        runPromise(index++)
      }
    }
    dequeue()
  })
}

export async function throttledGetAvatar(
  client: TelegramClient,
  agent: BskyAgent,
  users: Api.TypeUser[],
): Promise<any[]> {
  return new Promise(resolve => {
    const avatarArray: any[] = []
    let index = 0
    const getAvatar = async (i: number) => {
      try {
        const buf = await client.downloadProfilePhoto(users[i])
        if (buf) {
          const img = new Blob([buf], {type: 'image/jpeg'})
          const imgRef = await uploadBlobImage(agent, img)
          avatarArray.push(imgRef.data.blob.size ? imgRef.data.blob : undefined)
        } else {
          avatarArray.push(undefined)
        }
      } catch (error) {
        avatarArray.push(undefined)
      } finally {
        index++
        if (index === users.length || users.length === 0) {
          emitter.emit('avatarProgress', ``)
          resolve(avatarArray)
        } else {
          setTimeout(() => {
            getAvatar(index)
            emitter.emit('avatarProgress', `${index} / ${users.length}`)
          }, 1000)
        }
      }
    }

    getAvatar(0)
  })
}
