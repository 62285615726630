import React, {useEffect, useState} from 'react'
import {
  Keyboard,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import {StringSession} from '@sipz/telegram/sessions'
import {useQueryClient} from '@tanstack/react-query'

import {saveString} from '#/lib/storage'
import {isWeb} from '#/platform/detection'
import {emitter} from '#/state/events'
import {ITelegramModal, useModalControls} from '#/state/modals'
import {ContactItemProps} from '#/view/screens/Circle'
import {TELEGRAM_SESSION_KEY, TelegramBindStep} from './config'
import {telegramUserBaseInfo, useTelegramClient, useTelegramLink} from './hooks'
import {LoginBase} from './LoginBase'
import {LoginCode} from './LoginCode'
import {LoginPassWd} from './LoginPassWd'
import {TelegramAuthorize} from './TelegramAuthorize'
import {TelegramContacts} from './TelegramContacts'
import {TelegramMessage} from './TelegramMessage'
import {normalizePhone, userAuthParamCallback} from './util'

export const snapPoints = ['fullscreen']

export function Component({
  currentStep,
  userIds,
  onCallBack,
  circleId,
}: ITelegramModal) {
  const [phone, setPhone] = useState<string>()
  const [step, setStep] = useState<number>(TelegramBindStep.Init) // 1|2|3|4|5|6
  const [contacts, setContacts] = useState<ContactItemProps[]>()
  const [isExpired, setIsExpired] = useState<boolean>(false)
  const {client} = useTelegramClient()
  const {handleBind} = useTelegramLink()
  const [selectedUsers, setSelectUsers] = useState<string[]>([])
  const queryClient = useQueryClient()
  const {closeModal} = useModalControls()

  const onLogin = async (code: string, errorCb?: () => void) => {
    if (client && phone && code) {
      try {
        await client.connect()
        await client.start({
          phoneNumber: normalizePhone(phone),
          phoneCode: userAuthParamCallback(code),
          onError: async (err: any) => {
            if (err.message === 'Password is empty') {
              setStep(TelegramBindStep.Password)
            }
            if (err.code === 400 && err.errorMessage === 'PHONE_CODE_INVALID') {
              errorCb?.()
            }
            return true
          },
        })
        const sessionString = client.session.save()
        console.log('==== sessionString', sessionString)
        if (client.session instanceof StringSession && sessionString) {
          saveString(TELEGRAM_SESSION_KEY, sessionString)
          if (window.ReactNativeWebView) {
            const message = JSON.stringify({
              type: 'telegram_session',
              data: sessionString,
            })
            window.ReactNativeWebView.postMessage(message)
          } else {
            await handleBind(client)
            queryClient.invalidateQueries({queryKey: telegramUserBaseInfo})
            closeModal()
          }
        }
        // setStep(TelegramBindStep.Authorize)
      } catch (err: any) {
        if (err.message === 'Account has 2FA enabled.') {
          setStep(TelegramBindStep.Password)
        }
        if (err === 'Secondary binding') {
          closeModal()
        }
      }
    }
  }

  const onSelected = (ids: string[]) => {
    setSelectUsers(ids)
    setStep(TelegramBindStep.SendMessage)
  }

  useEffect(() => {
    if (currentStep) {
      setStep(currentStep)
    }
  }, [currentStep])

  useEffect(() => {
    const listenReLogin = () => {
      setIsExpired(true)
      setStep(TelegramBindStep.Init)
    }
    emitter.on('reLogin', listenReLogin)
    return () => {
      emitter.off('reLogin', listenReLogin)
    }
  }, [])

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      onPress={() => !isWeb && Keyboard.dismiss()}>
      <View style={[styles.page]}>
        {step === TelegramBindStep.Init && (
          <LoginBase
            client={client}
            isExpired={isExpired}
            updateLoginParams={p => {
              setPhone(p)
              setStep(TelegramBindStep.PhoneCode)
            }}
          />
        )}
        {step === TelegramBindStep.PhoneCode && (
          <LoginCode phone={phone} onLogin={onLogin} />
        )}
        {step === TelegramBindStep.Password && (
          <LoginPassWd
            loginSuccess={() => {
              closeModal()
              // setStep(TelegramBindStep.Authorize)
            }}
          />
        )}
        {step === TelegramBindStep.Authorize && (
          <TelegramAuthorize
            authSuccess={_contacts => {
              setStep(TelegramBindStep.SelectContact)
              setContacts(_contacts)
            }}
          />
        )}
        {step === TelegramBindStep.SelectContact && (
          <TelegramContacts contacts={contacts} onSelected={onSelected} />
        )}
        {step === TelegramBindStep.SendMessage && (
          <TelegramMessage
            userIds={userIds?.length ? userIds : selectedUsers}
            onCallBack={onCallBack}
            circleId={circleId}
            relogin={() => {
              setIsExpired(true)
              setStep(TelegramBindStep.Init)
            }}
          />
        )}
      </View>
    </TouchableWithoutFeedback>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    paddingHorizontal: 12,
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})
