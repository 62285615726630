import React, {useCallback, useEffect} from 'react'
import {Image, ScrollView, StyleSheet, Text, View} from 'react-native'
import {TouchableWithoutFeedback} from 'react-native-gesture-handler'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {emitter} from '#/state/events'
import {useAgent} from '#/state/session'
import {NavigationProp} from 'lib/routes/types'
import {useTelegramLink} from '#/view/com/modals/TelegramModal/hooks'
import {ICurrentCircleState} from '#/view/com/modals/TelegramModal/types'
import {getCurrentCircleState} from '#/view/com/modals/TelegramModal/util'
import {SdlButton} from '#/view/com/util/SdlButton'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {CenteredView} from '#/view/com/util/Views'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {useTheme} from '#/alf'

let chatList: number[] = []

export function CircleManageScreen() {
  const {_} = useLingui()
  const navigation = useNavigation<NavigationProp>()
  const {getCircleStatusList: getTgChatStates} = useTelegramLink()
  const [loading, setLoading] = React.useState(true)
  const [list, setList] = React.useState<ICurrentCircleState[]>([])
  const [progress, setProgress] = React.useState<string>('')

  const agent = useAgent()

  const getList = useCallback(async () => {
    try {
      setLoading(true)
      const circleStatusList = chatList.length
        ? chatList
        : await getTgChatStates()
      chatList = circleStatusList
      const res = await getCurrentCircleState(agent, circleStatusList)
      setList(res)
    } catch (error) {
      navigation.navigate('Home')
    } finally {
      setLoading(false)
    }
  }, [agent, getTgChatStates, navigation])

  const init = useCallback(async () => {
    getList()
  }, [getList])

  useEffect(() => {
    init()
    emitter.on('avatarProgress', setProgress)
    return () => {
      emitter.off('avatarProgress', setProgress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CenteredView style={{height: '100%'}}>
      <ViewHeader title={_(msg`Circles Manage`)} canGoBack />
      <View style={styles.page}>
        <ScrollView style={{height: '100%'}}>
          {list?.map(circle => {
            return (
              <CircleItem
                key={circle.id}
                circle={circle}
                setLoading={setLoading}
                getList={getList}
              />
            )
          })}
        </ScrollView>
        {loading && (
          <View style={styles.loading}>
            <Image
              source={{
                uri: '/sipzloading.gif',
              }}
              style={{width: 80, height: 80, borderRadius: 20}}
              accessibilityIgnoresInvertColors
            />
            {progress && <Text style={{color: 'white'}}>{progress}</Text>}
          </View>
        )}
      </View>
    </CenteredView>
  )
}

function CircleItem({
  circle,
  setLoading,
  getList,
}: {
  circle: ICurrentCircleState
  setLoading: (loading: boolean) => void
  getList: () => void
}) {
  const {refreshCircle} = useTelegramLink()
  const agent = useAgent()
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()

  const update = useCallback(
    async (data: ICurrentCircleState) => {
      setLoading(true)
      try {
        await refreshCircle(data.id, data.title)
      } catch (error) {
      } finally {
        setLoading(false)
      }
    },
    [refreshCircle, setLoading],
  )

  const goCircleMember = useCallback(() => {
    if (!circle.circleId) return
    navigation.navigate('CircleMembers', {id: `${circle.circleId}`})
  }, [circle.circleId, navigation])

  const create = useCallback(
    (data: ICurrentCircleState) => {
      agent.com.atproto.server
        .circleUserFromTelegramGroup({
          group_id: data.id,
          circle_name: data.title,
          circle_type: 1,
          description: '',
          is_public: true,
        })
        .then(() => {
          getList()
        })
    },
    [agent.com.atproto.server, getList],
  )

  return (
    <View style={styles.circleItem}>
      <CircleAvatar
        size={56}
        circle={{avatar: circle.photo, id: String(circle.id)}}
      />
      <View style={styles.circleTitle}>
        <TouchableWithoutFeedback
          accessibilityRole="button"
          onPress={goCircleMember}
          style={[styles.circleTitle, {height: '100%', paddingVertical: 0}]}>
          <Text style={[t.atoms.text, {fontWeight: 'bold'}]}>
            {circle.title}
          </Text>
          {!!circle.members && (
            <Text style={t.atoms.text}>
              {`${circle.members}  `}
              <Trans>members</Trans>
            </Text>
          )}
        </TouchableWithoutFeedback>
      </View>
      {circle.exited ? (
        <SdlButton text={'Update'} onPress={() => update(circle)} />
      ) : (
        <SdlButton
          bgStyle={{backgroundColor: '#96c482'}}
          text={'Create'}
          onPress={() => create(circle)}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 10,
    position: 'relative',
    flex: 1,
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.3)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circleItem: {
    marginVertical: 5,
    flexDirection: 'row',
  },
  circleTitle: {
    flex: 1,
    fontSize: 16,
    paddingHorizontal: 10,
    lineHeight: 30,
    justifyContent: 'space-between',
    paddingVertical: 5,
  },
})
